import React from "react"
import "../../components/my_global.scss"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Container, Row, Col } from "reactstrap"
import { UncontrolledCollapse, Button, CardBody, Card } from "reactstrap"

import "../../components/prizes.css"
// images
import { StaticImage } from "gatsby-plugin-image"

const Iamhydro = () => {
  return (
    <Layout>
      <SEO title={"What We're Reading"} />
      <div className="page-heading reading-bg">
        <Container>
          <Row>
            <Col lg="8" className="mx-auto text-center text-white">
              <h3 className="h3s"> American-Made Challenges</h3>
              <h2 className="h2s">I AM Hydro Prize</h2>
              <p
                className="page-hero-subheading"
                style={{ textAlign: "justify" }}
              >
                Make a big splash—help support the development of tomorrow’s
                hydropower technologies!
              </p>
              <p style={{ textAlign: "justify" }}>
                Advanced manufacturing is revolutionizing the way products are
                designed and built for countless industries by lowering costs,
                improving designs, and reducing production timelines.
              </p>
              <p style={{ textAlign: "justify" }}>
                The U.S. Department of Energy (DOE) Water Power Technologies
                Office (WPTO) launched the Innovations in Advanced Manufacturing
                for Hydropower (I AM Hydro) Prize to dramatically lower costs of
                hydropower components and systems designs by leveraging
                advancements in manufacturing and material.
              </p>
              <p style={{ textAlign: "justify" }}>
                I AM Hydro innovators competed for up to $175,000 in cash prizes
                and helped support the hydropower industry using the ingenuity
                of advanced manufacturing.
              </p>
              <p style={{ textAlign: "justify" }}>Up for the challenge?</p>
              <div className="column is-12">
                <div className="has-text-centered">
                  <a href="https://www.herox.com/iamhydro">
                    <button type="button" className="btns">
                      Follow the Challenge?
                    </button>
                  </a>
                </div>
                <span className="whitespace"></span>
              </div>
            </Col>
          </Row>
          <StaticImage src="../../images/stick.webp" className="stick" alt="stick" loading="eager"/>
        </Container>
      </div>
      <div className="section_padding">
        <div className="container">
          <span style={{ padding: "5px" }}></span>
          <p className="pcol-fz">
            Advanced manufacturing technologies are revolutionizing the way
            products are designed and built across countless industries. And
            while the benefits of advanced manufacturing are well-understood,
            the high-impact opportunities for incorporating it into hydropower
            have yet to be tapped.
          </p>
          <p className="pcol-fz">
            The I AM Hydro Prize sought innovative solutions to strengthen
            hydropower by applying advanced manufacturing technologies to reduce
            construction costs and repair frequency, improve efficiency and
            energy capture, and more.
          </p>
          <span style={{ padding: "5px" }}></span>
          <div className="row">
            <div className="col">
              <h4>
                <b>I AM Hydro was designed to:</b>
              </h4>
              <ul className="boxy-bullets gold-bullets ps list-styled">
                <li>
                  Spur innovation by engaging a national community of
                  visionaries who are not typically associated with the
                  hydropower industry
                </li>
                <li>
                  Target levelized cost of electricity (LCOE) reductions in
                  hydropower design, manufacturing, and operation and
                  maintenance.
                </li>
              </ul>
            </div>
            <div className="col">
              <div>
                <StaticImage src="../../images/iam.jpg" className="img-styl" alt="iam" loading="lazy"/>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <span style={{ padding: "5px" }}></span>
          <h1 className="mac_h1">
            Congratulations to our CONCEPT Stage Winners!
          </h1>
          <hr />
          <p>
            Congratulations to the 11 winners, who received up to $175,000 in
            cash prizes! They are:
          </p>
          <div>
            <table class="table is-hoverable is-fullwidth has-bg-light-blue has-text-default">
              <thead>
                <tr>
                  <th>
                    entry title{" "}
                    <span class="is-size-7 has-text-weight-normal">
                      (listed alphabetically)
                    </span>
                  </th>
                  <th className="tds">team</th>
                  <th class="has-text-centered">video</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/iamhydro/round/656/entry/33531"
                      class="has-text-default"
                    >
                      Additive Manufacturing Evaporative Casting (AMEC)
                    </a>
                  </td>
                  <td className="tds">Sarah Jordan/Skuld LLC</td>
                  <td class="has-text-centered">
                    <Button
                      color="primary"
                      id="click"
                      style={{ marginBottom: "1rem" }}
                    >
                      Play
                    </Button>
                  </td>
                </tr>
                <div className="yt4">
                  <UncontrolledCollapse toggler="#click">
                    <div>
                      <iframe
                        width="100%"
                        height="444px"
                        src="https://www.youtube.com/embed/GF4o2fIEmeA?auto=1&mute=1"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </UncontrolledCollapse>
                </div>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/iamhydro/round/656/entry/33492"
                      class="has-text-default"
                    >
                      AI Driven Optimization of Turbine Blades
                    </a>
                  </td>
                  <td className="tds">John Newport</td>
                  <td class="has-text-centered">
                    <Button
                      color="primary"
                      id="click2"
                      style={{ marginBottom: "1rem" }}
                    >
                      Play
                    </Button>
                  </td>
                </tr>
                <div className="yt4">
                  <UncontrolledCollapse toggler="#click2">
                    <div>
                      <iframe
                        width="100%"
                        height="444px"
                        src="https://www.youtube.com/embed/uSGIRqdKyHw?auto=1&mute=1"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </UncontrolledCollapse>
                </div>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/iamhydro/round/656/entry/33582"
                      class="has-text-default"
                    >
                      Anti-fouling Coating for Hydropower Cost Reduction
                    </a>
                  </td>
                  <td className="tds">Interphase Materials</td>
                  <td class="has-text-centered">
                    <Button
                      color="primary"
                      id="click3"
                      style={{ marginBottom: "1rem" }}
                    >
                      Play
                    </Button>
                  </td>
                </tr>
                <div className="yt4">
                  <UncontrolledCollapse toggler="#click3">
                    <div>
                      <iframe
                        width="100%"
                        height="444px"
                        src="https://www.youtube.com/embed/0P5cZ9Fc7i4?auto=1&mute=1"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </UncontrolledCollapse>
                </div>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/iamhydro/round/656/entry/33593"
                      class="has-text-default"
                    >
                      Augmented Repair via Additive Manufacturing
                    </a>
                  </td>
                  <td className="tds">
                    Joey Griffiths/Fusion-Free Fabrication
                  </td>
                  <td class="has-text-centered">
                    <Button
                      color="primary"
                      id="click4"
                      style={{ marginBottom: "1rem" }}
                    >
                      Play
                    </Button>
                  </td>
                </tr>
                <div className="yt4">
                  <UncontrolledCollapse toggler="#click4">
                    <div>
                      <iframe
                        width="100%"
                        height="444px"
                        src="https://www.youtube.com/embed/M0iDaAZhmBE?auto=1&mute=1"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </UncontrolledCollapse>
                </div>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/iamhydro/round/656/entry/33592"
                      class="has-text-default"
                    >
                      Composite Magnet for Hydropower Generators
                    </a>
                  </td>
                  <td className="tds">Kaizhong Gao/Composite Magnet</td>
                  <td class="has-text-centered">
                    <Button
                      color="primary"
                      id="click5"
                      style={{ marginBottom: "1rem" }}
                    >
                      Play
                    </Button>
                  </td>
                </tr>
                <div className="yt4">
                  <UncontrolledCollapse toggler="#click5">
                    <div>
                      <iframe
                        width="100%"
                        height="444px"
                        src="https://www.youtube.com/embed/bItGEJBbLuM?auto=1&mute=1"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </UncontrolledCollapse>
                </div>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/iamhydro/round/656/entry/29331"
                      class="has-text-default"
                    >
                      CRUST – Ceramic Rust Universal Sealant Technology
                    </a>
                  </td>
                  <td className="tds">Barret Schlegelmilch</td>
                  <td class="has-text-centered">
                    <Button
                      color="primary"
                      id="click6"
                      style={{ marginBottom: "1rem" }}
                    >
                      Play
                    </Button>
                  </td>
                </tr>
                <div className="yt4">
                  <UncontrolledCollapse toggler="#click6">
                    <div>
                      <iframe
                        width="100%"
                        height="444px"
                        rc="https://www.youtube.com/embed/H0kwux_EbpE?auto=1&mute=1"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </UncontrolledCollapse>
                </div>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/iamhydro/round/656/entry/33272"
                      class="has-text-default"
                    >
                      Lowering Costs with Mechanical Metamaterials
                    </a>
                  </td>
                  <td className="tds">Jesse Silverberg/Multiscale Systems</td>
                  <td class="has-text-centered">
                    <Button
                      color="primary"
                      id="click7"
                      style={{ marginBottom: "1rem" }}
                    >
                      Play
                    </Button>
                  </td>
                </tr>
                <div className="yt4">
                  <UncontrolledCollapse toggler="#click7">
                    <div>
                      <iframe
                        width="100%"
                        height="444px"
                        src="https://www.youtube.com/embed/bMR5f98g6a4?auto=1&mute=1"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </UncontrolledCollapse>
                </div>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/iamhydro/round/656/entry/33578"
                      class="has-text-default"
                    >
                      Retrofitting of NPDs Using 3D Concrete Printing
                    </a>
                  </td>
                  <td className="tds">Jason Cotrell</td>
                  <td class="has-text-centered">
                    <Button
                      color="primary"
                      id="click8"
                      style={{ marginBottom: "1rem" }}
                    >
                      Play
                    </Button>
                  </td>
                </tr>
                <div className="yt4">
                  <UncontrolledCollapse toggler="#click8">
                    <div>
                      <iframe
                        width="100%"
                        height="444px"
                        src="https://www.youtube.com/embed/Qth7bpeLPI0?auto=1&mute=1"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </UncontrolledCollapse>
                </div>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/iamhydro/round/656/entry/32483"
                      class="has-text-default"
                    >
                      Semi-Solid Metal Casting for Hydro-Turbines
                    </a>
                  </td>
                  <td className="tds">Baha Abulnaga</td>
                  <td class="has-text-centered">
                    <Button
                      color="primary"
                      id="click9"
                      style={{ marginBottom: "1rem" }}
                    >
                      Play
                    </Button>
                  </td>
                </tr>
                <div className="yt4">
                  <UncontrolledCollapse toggler="#click9">
                    <div>
                      <iframe
                        width="100%"
                        height="444px"
                        src="https://www.youtube.com/embed/BEhmpT-hM8I?auto=1&mute=1"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </UncontrolledCollapse>
                </div>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/iamhydro/round/656/entry/32468"
                      class="has-text-default"
                    >
                      Super-Frictionless Surfaces, Quasi-R
                    </a>
                  </td>
                  <td className="tds">Jainagesh Sekhar</td>
                  <td class="has-text-centered">
                    <Button
                      color="primary"
                      id="click10"
                      style={{ marginBottom: "1rem" }}
                    >
                      Play
                    </Button>
                  </td>
                </tr>
                <div className="yt4">
                  <UncontrolledCollapse toggler="#click10">
                    <div>
                      <iframe
                        width="100%"
                        height="444px"
                        src="https://www.youtube.com/embed/2-zHHWu-76Q?auto=1&mute=1"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </UncontrolledCollapse>
                </div>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/iamhydro/round/656/entry/32535"
                      class="has-text-default"
                    >
                      Utility of Large Area AM for Small Hydro
                    </a>
                  </td>
                  <td className="tds">Randal J. Mueller</td>
                  <td class="has-text-centered">
                    <Button
                      color="primary"
                      id="click11"
                      style={{ marginBottom: "1rem" }}
                    >
                      Play
                    </Button>
                  </td>
                </tr>
                <div className="yt4">
                  <UncontrolledCollapse toggler="#click11">
                    <div>
                      <iframe
                        width="100%"
                        height="444px"
                        src="https://www.youtube.com/embed/ptZorXojols?auto=1&mute=1"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </UncontrolledCollapse>
                </div>
                <tr></tr>
              </tbody>
            </table>
          </div>
        </div>
        <span style={{ padding: "5px" }}></span>

        <div className="container">
          <h1 className="mac_h1">More CONCEPT Stage Ideas</h1>
          <hr />
          <p className="pcol-fz">
            We would also like to applaud those who participated in the I AM
            Hydro Prize that were not chosen. Learn more about these teams and
            their solutions to help support the development of tomorrow’s
            hydropower technologies.
          </p>
          <div>
            <table class="table is-hoverable is-fullwidth has-bg-white has-text-default">
              <thead>
                <tr>
                  <th>
                    entry title{" "}
                    <span class="is-size-7 has-text-weight-normal">
                      (listed alphabetically)
                    </span>
                  </th>
                  <th className="tds">team</th>
                  <th class="has-text-centered">video</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/iamhydro/round/656/entry/30161"
                      class="has-text-default"
                    >
                      Above Ground GVvFES Hydropower
                    </a>
                  </td>
                  <td className="tds"> Bingaman</td>
                  <td class="has-text-centered">
                    <Button
                      color="primary"
                      id="concept"
                      style={{ marginBottom: "1rem" }}
                    >
                      Play
                    </Button>
                  </td>
                </tr>
                <div className="yt5">
                  <UncontrolledCollapse toggler="#concept">
                    <div>
                      <iframe
                        width="100%"
                        height="444px"
                        src="https://www.youtube.com/embed/jbCpIbSEEd4?auto=1&mute=1"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </UncontrolledCollapse>
                </div>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/iamhydro/round/656/entry/33267"
                      class="has-text-default"
                    >
                      Floatovoltaics a Solution to a Glaring Problem
                    </a>
                  </td>
                  <td className="tds">John McIntyre</td>
                  <td class="has-text-centered">
                    <Button
                      color="primary"
                      id="concept2"
                      style={{ marginBottom: "1rem" }}
                    >
                      Play
                    </Button>
                  </td>
                </tr>
                <div className="yt5">
                  <UncontrolledCollapse toggler="#concept2">
                    <div>
                      <iframe
                        width="100%"
                        height="444px"
                        src="https://www.youtube.com/embed/nVqL849o_Bc?auto=1&mute=1"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </UncontrolledCollapse>
                </div>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/iamhydro/round/656/entry/28394"
                      class="has-text-default"
                    >
                      founder
                    </a>
                  </td>
                  <td className="tds">Anthony Megaro</td>
                  <td class="has-text-centered">
                    <Button
                      color="primary"
                      id="concept3"
                      style={{ marginBottom: "1rem" }}
                    >
                      Play
                    </Button>
                  </td>
                </tr>
                <div className="yt5">
                  <UncontrolledCollapse toggler="#concept3">
                    <div>
                      <iframe
                        width="100%"
                        height="444px"
                        src="https://www.youtube.com/embed/dPl94671Llw?auto=1&mute=1"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </UncontrolledCollapse>
                </div>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/iamhydro/round/656/entry/32330"
                      class="has-text-default"
                    >
                      Hydro Power Project for sediment laden water
                    </a>
                  </td>
                  <td className="tds">Rakesh Kasba</td>
                  <td class="has-text-centered">
                    <Button
                      color="primary"
                      id="concept4"
                      style={{ marginBottom: "1rem" }}
                    >
                      Play
                    </Button>
                  </td>
                </tr>
                <div className="yt5">
                  <UncontrolledCollapse toggler="#concept4">
                    <div>
                      <iframe
                        width="100%"
                        height="444px"
                        src="https://www.youtube.com/embed/jPpswcp5Igo?auto=1&mute=1"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </UncontrolledCollapse>
                </div>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/iamhydro/round/656/entry/29103"
                      class="has-text-default"
                    >
                      Hydro power systems technologies
                    </a>
                  </td>
                  <td className="tds">Pinku Kumar</td>
                  <td class="has-text-centered">
                    <Button
                      color="primary"
                      id="concept5"
                      style={{ marginBottom: "1rem" }}
                    >
                      Play
                    </Button>
                  </td>
                </tr>
                <div className="yt5">
                  <UncontrolledCollapse toggler="#concept5">
                    <div>
                      <iframe
                        width="100%"
                        height="444px"
                        src="https://www.youtube.com/embed/GG0Dm-o0nDo?auto=1&mute=1"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </UncontrolledCollapse>
                </div>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/iamhydro/round/656/entry/28648"
                      class="has-text-default"
                    >
                      Imploturbocompressor Impulse Turbine /Implo-Ductet
                    </a>
                  </td>
                  <td className="tds"> Carlos Barrera</td>
                  <td class="has-text-centered">
                    <Button
                      color="primary"
                      id="concept6"
                      style={{ marginBottom: "1rem" }}
                    >
                      Play
                    </Button>
                  </td>
                </tr>
                <div className="yt5">
                  <UncontrolledCollapse toggler="#concept6">
                    <div>
                      <iframe
                        width="100%"
                        height="444px"
                        src="https://www.youtube.com/embed/WxBAnl3KaFc?auto=1&mute=1"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </UncontrolledCollapse>
                </div>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/iamhydro/round/656/entry/33559"
                      class="has-text-default"
                    >
                      Let fish go upstream and return to their hometown
                    </a>
                  </td>
                  <td className="tds">Bomin Shen</td>
                  <td class="has-text-centered">
                    <Button
                      color="primary"
                      id="concept7"
                      style={{ marginBottom: "1rem" }}
                    >
                      Play
                    </Button>
                  </td>
                </tr>
                <div className="yt5">
                  <UncontrolledCollapse toggler="#concept7">
                    <div>
                      <iframe
                        width="100%"
                        height="444px"
                        src="https://www.youtube.com/embed/tWwMK43CE7Y?auto=1&mute=1"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </UncontrolledCollapse>
                </div>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/iamhydro/round/656/entry/33590"
                      class="has-text-default"
                    >
                      Metal 3D printing for Corrosive Environments
                    </a>
                  </td>
                  <td className="tds">Venkata Krishnan Sampath</td>
                  <td class="has-text-centered">
                    <Button
                      color="primary"
                      id="concept8"
                      style={{ marginBottom: "1rem" }}
                    >
                      Play
                    </Button>
                  </td>
                </tr>
                <div className="yt5">
                  <UncontrolledCollapse toggler="#concept8">
                    <div>
                      <iframe
                        width="100%"
                        height="444px"
                        src="https://www.youtube.com/embed/K9U-4vStb14?auto=1&mute=1"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </UncontrolledCollapse>
                </div>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/iamhydro/round/656/entry/33595"
                      class="has-text-default"
                    >
                      Multi-Functional Integrated Hydro Power System
                    </a>
                  </td>
                  <td className="tds">Francisco Ragonese</td>
                  <td class="has-text-centered">
                    <Button
                      color="primary"
                      id="concept9"
                      style={{ marginBottom: "1rem" }}
                    >
                      Play
                    </Button>
                  </td>
                </tr>
                <div className="yt5">
                  <UncontrolledCollapse toggler="#concept9">
                    <div>
                      <iframe
                        width="100%"
                        height="444px"
                        src="https://www.youtube.com/embed/IzKEFVpfi5U?auto=1&mute=1"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </UncontrolledCollapse>
                </div>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/iamhydro/round/656/entry/32892"
                      class="has-text-default"
                    >
                      Reclaim Energies Captures Untapped Hydropower
                    </a>
                  </td>
                  <td className="tds">Ward Dill</td>
                  <td class="has-text-centered">
                    <Button
                      color="primary"
                      id="concept10"
                      style={{ marginBottom: "1rem" }}
                    >
                      Play
                    </Button>
                  </td>
                </tr>
                <div className="yt5">
                  <UncontrolledCollapse toggler="#concept10">
                    <div>
                      <iframe
                        width="100%"
                        height="444px"
                        src="https://www.youtube.com/embed/BrZEBrAMDXA?auto=1&mute=1"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </UncontrolledCollapse>
                </div>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/iamhydro/round/656/entry/33580"
                      class="has-text-default"
                    >
                      Submersed Pumped Hydro Storage
                    </a>
                  </td>
                  <td className="tds">Osi Van Dessel</td>
                  <td class="has-text-centered">
                    <Button
                      color="primary"
                      id="concept11"
                      style={{ marginBottom: "1rem" }}
                    >
                      Play
                    </Button>
                  </td>
                </tr>
                <div className="yt5">
                  <UncontrolledCollapse toggler="#concept11">
                    <div>
                      <iframe
                        width="100%"
                        height="444px"
                        src="https://www.youtube.com/embed/EfPVPqe3FXc?auto=1&mute=1"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </UncontrolledCollapse>
                </div>
                <tr></tr>
              </tbody>
            </table>
          </div>
          <p className="pcol-fz">
            To make more targeted investments in the future, WPTO is identifying
            high-impact opportunities for advanced manufacturing to address
            critical challenges in hydropower generation.
          </p>
          <p className="pcol-fz">
            The CONCEPT Stage is now closed. As part of this prize, talented
            innovators helped guide us toward tomorrow’s hydropower technology.
            Competitors were challenged to develop concepts and ideas that will
            form the foundation of WPTO’s advanced manufacturing strategy.
          </p>
          <p className="pcol-fz">
            While the prize solicited solutions of all shapes and sizes,
            organizers identified the following potential focus areas for
            submissions:
          </p>
          <ul className="boxy-bullets gold-bullets ps list-styled">
            <li>Additive Manufacturing</li>
            <li>Advanced Materials</li>
            <li>Casting, Forming, and Machining</li>
            <li>Joining, Coating, and Repair.</li>
          </ul>
        </div>

        <span style={{ padding: "5px" }}></span>

        <div className="container">
          <h1 className="mac_h1">Important Dates</h1>
          <hr />
          <div className="row rw">
            <div className="col">
              <ul className="boxy-bullets gold-bullets ps list-styled">
                <li>
                  <b>Prize open: </b>
                  June 30, 2020
                </li>
                <li>
                  <b>Prize closes: </b>
                  October 30, 2020
                </li>
                <li>
                  <b>Application Review: </b>
                  November 2, 2020
                </li>
                <li>
                  <b>Winners Announcement: </b>
                  December 2020
                </li>
              </ul>
            </div>
            <div className="col">
              <div>
                <StaticImage
                  src="../../images/iam2.webp"
                  className="img-styl"
                  alt="iam2"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <p className="pcol-fz">
            <b>
              Dates are forthcoming and subject to change. Final dates will be
              posted here and on the HeroX site.
            </b>
          </p>
          <p>
            Sign up for updates on our{" "}
            <a href="https://www.herox.com/iamhydro">HeroX challenge page</a>.
          </p>
          <span style={{ padding: "5px" }}></span>
          <div>
            <p className="pcol-fz">
              Innovators, visionaries, or enthusiasts from across a variety of
              industries are encouraged to join the Groundbreaking Hydro Prize
              community and find like-minded collaborators. Help us build a
              better base from which tomorrow’s hydropower can thrive. Contact
              groundbreaking.hydro@nrel.gov with your industry feedback,
              questions and ideas!
            </p>
          </div>
        </div>
        <div className="container">
          <h1 className="mac_h1">Who could participate?</h1>
          <hr />
          <p className="pcol-fz">
            Any innovators, visionaries, or enthusiasts with an idea to apply
            advanced manufacturing techniques to hydropower developments can
            apply! Join the community as an individual and find like-minded
            collaborators.
          </p>
        </div>
        <span style={{ padding: "5px" }}></span>
        <div className="container">
          <h3 className="h3ps mac_h1">
            {" "}
            Solutions that help shape the future{" "}
          </h3>
          <div>
            <StaticImage src="../../images/iam3.jpg" className="img-styl" alt="iam3" loading="lazy"/>
          </div>
          <p className="hps">
            Are you a thinker, entrepreneur, facility, or potential partner?
            Anyone with an innovative idea can help promote transformation by
            participating in the American-Made Challenges.
          </p>
          <div style={{ textAlign: "center" }}>
            <a href="https://www.herox.com/iamhydro">
              <button type="button" className="btns">
                Ready for a Challenge?
              </button>
            </a>
          </div>
        </div>

        <span style={{ padding: "5px" }}></span>
      </div>
    </Layout>
  )
}

export default Iamhydro
